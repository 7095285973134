<template>
  <wrapper-page bg="1">
    <template slot="header">
      <c-icon-star color="green"></c-icon-star>
      <h1>Пользовательское соглашение</h1>
    </template>
  </wrapper-page>
</template>

<script>
import CIconStar from "@/components/design/c-icon-star";
import api from "@/api";
import WrapperPage from "@/components/wrapper/w-page";
export default {
  name: "ArticlesPage",
  components: {WrapperPage, CIconStar },
  data: function () {
    return {
      articles: []
    }
  },
  async mounted() {
    this.$store.commit("setBackground", "page");
    this.articles = await api.loadArticles()
  },
};
</script>

<style lang="sass">
@import "src/assets/css/media"
.article
  width: 100%
  display: flex
  flex-wrap: wrap
  justify-content: center
  padding-bottom: 50px
  &-item
    max-width: 400px
    width: 100%
    min-width: 250px
    padding: 25px 20px
    margin-bottom: 15px
    border: 1px solid #DDDDDD
    border-radius: 12px
    text-decoration: none
    @include media-sm
      width: calc(50% - 50px)
      margin: 0 10px 20px
    @include media-lg
      width: calc(33% - 30px)
      margin: 0 10px 40px
    @include media-xl
      width: calc(25% - 30px)
    &:focus
      outline: none
      border: 1px solid #888888
    &__img
      margin-bottom: 15px
      img
        width: 100%
    &__num
      width: 20px
      display: inline-block
    &__title
      font-size: 15px
      font-weight: bold
      margin-bottom: 10px
      color: #000
      @include media-sm
        font-size: 15px
      @include media-lg
        font-size: 16px
      @include media-xl
        font-size: 18px
    &__desc
      font-size: 15px
      line-height: 140%
      color: #979797
      @include media-sm
        font-size: 15px
      @include media-lg
        font-size: 16px
      @include media-xl
        font-size: 18px
  &-video
    margin: 0 auto
    max-width: 700px
    text-align: center
    padding-bottom: 100px
    h2
      margin-bottom: 30px
      font-size: 1.8rem
      line-height: 130%
    p
      font-size: 1.4rem
      line-height: 140%
      margin-bottom: 60px
    iframe
      max-width: 100%
</style>
